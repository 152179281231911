import React, { useState } from 'react';
import './App.css';

function App() {
  const [leetText, setLeetText] = useState('');
  const [humanText, setHumanText] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    console.log("Submit was called!");
  }

  const leetReplacements = {
    'l': '1',
    'e': '3',
    'a': '4',
    's': '5',
    't': '7',
    'b': '8',
    'g': '9',
    'o': '0'
  };

  function to_leet() {
    const inputElement = document.querySelector('textarea[name="input-leet"]');
    const text = inputElement.value;
    const replacedText = replace_chars(text, leetReplacements);
    
    setLeetText(replacedText);
  }

  function to_human() {
    const inputElement = document.querySelector('textarea[name="input-human"]');
    const text = inputElement.value;
    const replacedText = replace_chars(text, swap_key_values(leetReplacements));
    
    setHumanText(replacedText);
  }

  function handle_human_text_change(e) {
    setHumanText(e.target.value);
  }

  function handle_leet_text_change(e) {
    setLeetText(e.target.value);
  }

  function replace_chars(text, map) {
    var replacedText = '';
    for (let i = 0; i < text.length; i++) {
      const char = text[i];

      if (map.hasOwnProperty(char)) {
        replacedText += map[char];
      } else {
        replacedText += char;
      }
    }

    return replacedText;
  }  

  function swap_key_values(obj) {
    const swappedReplacements = {};

    for (const key in obj) {
      const value = obj[key];
      swappedReplacements[value] = key;
    }

    return swappedReplacements;
  } 

  return (
    <div>
    <h2 className="content-subhead">Human readable text</h2>
    <form className="pure-form" onSubmit={handleSubmit}>
      <fieldset className="pure-group">
        <textarea className="pure-input-1-2" value={humanText} placeholder="Human readable text to convert to leet speak" name="input-leet" onChange={handle_human_text_change}></textarea>
      </fieldset>
      <button type="submit" className="pure-button pure-input-1-2 pure-button-primary" onClick={to_leet}>1337!</button>

      <h2 className="content-subhead">1337 text</h2>
      <fieldset className="pure-group">
        <textarea className="pure-input-1-2" value={leetText} placeholder="leet speak text to convert to human readable text" name="input-human" onChange={handle_leet_text_change}></textarea>
      </fieldset>
      <button type="submit" className="pure-button pure-input-1-2 pure-button-primary" onClick={to_human}>Decode!</button>
  </form>
  </div>
  );
}

export default App;
